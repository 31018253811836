const vehicleModels = {
  AC: {
    Aceca: [],
    Cobra: []
  },
  Acura: {
    CL: [],
    CSX: [],
    EL: [],
    ILX: [],
    "ILX Hybrid": ["electroConfig1"],
    Integra: [],
    MDX: [],
    NSX: [],
    RDX: [],
    RL: [],
    RLX: [],
    RSX: [],
    TL: [],
    TLX: [],
    TSX: [],
    ZDX: []
  },
  "Alfa Romeo": {
    "145": [],
    "146": [],
    "147": ["municdirect"],
    "156": [],
    "159": [],
    "166": [],
    "4C": ["electroConfig1"],
    "8C Competizione": [],
    Brera: [],
    Centauri: [],
    Crosswagon: [],
    GT: [],
    GTV: [],
    Giulia: ["electroConfig1"],
    Giulietta: [],
    Kamal: [],
    MiTo: [],
    Spider: [],
    Sportwagon: [],
    Stelvio: ["electroConfig1"]
  },
  Alpina: {
    B10: [],
    B3: [],
    B5: [],
    "B6 Gran Coup\u00e9": [],
    B7: [],
    D3: [],
    D5: [],
    Roadster: []
  },
  Ascari: {
    A10: [],
    Ecosse: [],
    KZ1: []
  },
  "Aston Martin": {
    Cygnet: [],
    DB11: [],
    DB7: [],
    DB9: [],
    DBS: [],
    "DBS Superleggera": [],
    "One-77": [],
    Rapide: [],
    "Rapide S": [],
    V12: [],
    "V12 Vantage": [],
    "V12 Zagato": [],
    "V8 Vantage": [],
    Vanquish: [],
    Vantage: [],
    Virage: []
  },
  Audi: {
    A1: [],
    A2: [],
    A3: [],
    "A3 Cabriolet": [],
    "A3 e-tron": ["electroConfig1"],
    A4: [],
    "A4 Avant": [],
    "A4 Cabriolet": [],
    A5: [],
    "A5 Cabriolet": [],
    "A5 Coup\u00e9": [],
    A6: [],
    "A6 Avant": [],
    "A6 Avant Hybrid": ["electroConfig1"],
    A7: [],
    A8: [],
    "A8 e-tron": ["electroConfig1"],
    AL2: [],
    Allroad: [],
    Avantissimo: [],
    LeMans: [],
    "Nuvolari Quattro": [],
    "Pikes Peak Quattro": [],
    Q1: [],
    Q2: [],
    Q3: [],
    Q5: [],
    "Q5 Hybrid": ["electroConfig1"],
    Q7: [],
    "Q7 e-tron": ["electroConfig1"],
    Q8: [],
    R8: [],
    "R8 Coup\u00e9": [],
    "R8 Spyder": [],
    "RS 5": [],
    "RS 7": [],
    RS3: [],
    RS4: [],
    "RS4 Avant": [],
    RS5: [],
    "RS5 Cabriolet": [],
    "RS5 Coup\u00e9": [],
    RS6: ["municdirect"],
    "RS6 Avant": ["municdirect"],
    RS7: ["municdirect"],
    RSQ3: [],
    S1: [],
    S3: [],
    "S3 Cabriolet": [],
    S4: [],
    "S4 Avant": [],
    S5: [],
    "S5 Cabriolet": [],
    "S5 Coup\u00e9": [],
    S6: [],
    "S6 Avant": [],
    S7: [],
    S8: [],
    SQ5: [],
    SQ7: [],
    SQ8: [],
    TT: [],
    "TT Cabrio": [],
    "TT Coup\u00e9": [],
    "TT RS Coup\u00e9": [],
    "TT RS Roadster": [],
    "TT RS plus": [],
    "TT Roadster": [],
    TTS: [],
    "TTS Coup\u00e9": [],
    "TTS Roadster": [],
    "e-tron": ["electroConfig1"]
  },
  Avanti: {
    "Sport Convertible": [],
    Studebaker: []
  },
  BMW: {
    "1er": ["bmw_alarm"],
    "1er Cabrio": ["bmw_alarm"],
    "1er Coup\u00e9": ["bmw_alarm"],
    "2er": ["bmw_alarm"],
    "2er ActiveTourer": ["bmw_alarm"],
    "2er ActiveTourer Hybrid": ["bmw_alarm", "electroConfig1"],
    "2er Cabrio": ["bmw_alarm"],
    "2er Grand Tourer": ["bmw_alarm"],
    "3er": ["bmw_alarm"],
    "3er Cabrio": ["bmw_alarm"],
    "3er Cabriolet": ["bmw_alarm"],
    "3er Compact": ["bmw_alarm"],
    "3er Coup\u00e9": ["bmw_alarm", "municdirect"],
    "3er GT": ["bmw_alarm"],
    "3er Touring": ["bmw_alarm"],
    "4er": ["bmw_alarm"],
    "4er Cabrio": ["bmw_alarm"],
    "4er Gran Coup\u00e9": ["bmw_alarm"],
    "5er": ["bmw_alarm"],
    "5er GT": ["bmw_alarm"],
    "5er Hybrid": ["bmw_alarm", "electroConfig1"],
    "5er Touring": ["bmw_alarm"],
    "6er Cabrio": ["bmw_alarm"],
    "6er Cabriolet": ["bmw_alarm"],
    "6er Coup\u00e9": ["bmw_alarm"],
    "6er GT": ["bmw_alarm"],
    "6er Grand Coup\u00e9": ["bmw_alarm"],
    "7er": ["bmw_alarm", "municdirect"],
    "8er": ["bmw_alarm"],
    "ActiveHybrid 3": ["bmw_alarm", "electroConfig1"],
    "ActiveHybrid 5": ["bmw_alarm", "electroConfig1"],
    "ActiveHybrid 7": ["bmw_alarm", "electroConfig1"],
    M2: ["bmw_alarm"],
    M3: ["bmw_alarm"],
    "M3 Cabriolet": ["bmw_alarm"],
    "M3 Coup\u00e9": ["bmw_alarm"],
    M4: ["bmw_alarm"],
    "M4 Cabriolet": ["bmw_alarm"],
    "M4 Coup\u00e9": ["bmw_alarm"],
    M5: ["bmw_alarm"],
    M6: ["bmw_alarm"],
    "M6 Cabriolet": ["bmw_alarm"],
    "M6 Coup\u00e9": ["bmw_alarm"],
    "M6 Gran Coup\u00e9": ["bmw_alarm"],
    X1: ["bmw_alarm"],
    X2: ["bmw_alarm"],
    X3: ["bmw_alarm"],
    X4: ["bmw_alarm"],
    "X4 M": ["bmw_alarm"],
    X5: ["bmw_alarm"],
    "X5 Hybrid": ["bmw_alarm", "electroConfig1"],
    "X5 M": ["bmw_alarm"],
    X6: ["bmw_alarm"],
    "X6 Hybrid": ["bmw_alarm", "electroConfig1"],
    "X6 M": ["bmw_alarm"],
    X7: ["bmw_alarm"],
    Z18: ["bmw_alarm"],
    Z22: ["bmw_alarm"],
    Z3: ["bmw_alarm"],
    Z4: ["bmw_alarm"],
    "Z4 Coup\u00e9": ["bmw_alarm"],
    "Z4 Roadster": ["bmw_alarm"],
    Z8: ["bmw_alarm"],
    Z9: ["bmw_alarm"],
    "Z9 Gran Turismo Convertible": ["bmw_alarm"],
    i3: ["bmw_alarm", "electroConfig1"],
    i8: ["bmw_alarm", "electroConfig1"]
  },
  Bentley: {
    Arnage: [],
    Azure: [],
    Bentayga: [],
    Brooklands: [],
    Continental: [],
    "Continental Flying Spur": [],
    "Continental GT": [],
    "Continental GTC": [],
    "Continental Supersports": [],
    "Continental T": [],
    "Exp Speed 8": [],
    "Flying Spur": [],
    "GT3-R": [],
    GTC: [],
    Mulsanne: [],
    "State Limousine": []
  },
  Brilliance: {
    BC3: [],
    BS4: [],
    BS6: []
  },
  Bristol: {
    Blenheim: [],
    Fighter: [],
    "Project Fighter": []
  },
  Bugatti: {
    Chiron: [],
    "EB 118": [],
    "EB 16.4 Veyron": [],
    "EB 218": [],
    Veyron: [],
    "Veyron 16.4 Grand Sport": [],
    "Veyron 16.4 Grand Sport Vitesse": [],
    "Veyron 16.4 Super Sport": []
  },
  Buick: {
    Blackhawk: [],
    Centieme: [],
    Century: [],
    Cielo: [],
    Enclave: [],
    Encore: [],
    Envision: [],
    LaCrosse: [],
    LeSabre: [],
    Lucerne: [],
    "Park Avenue": [],
    Rainier: [],
    Regal: [],
    Rendezvous: [],
    Terraza: [],
    Verano: []
  },
  Cadillac: {
    ATS: [],
    "ATS Coup\u00e9": [],
    "ATS-V": [],
    BLS: [],
    CT6: [],
    "CT6 Hybrid": ["electroConfig1"],
    CTS: [],
    "CTS Coup\u00e9": [],
    "CTS Wagon": [],
    "CTS-V": [],
    "CTS-V Coup\u00e9": [],
    "CTS-V Wagon": [],
    Catera: [],
    DTS: [],
    DeVille: [],
    ELR: [],
    Eldorado: [],
    Escalade: [],
    "Escalade ESV": [],
    "Escalade Hybrid": ["electroConfig1"],
    LMP: [],
    "Le Mans": [],
    SRX: [],
    STS: [],
    "STS-V": [],
    Seville: [],
    "V Sixteen": [],
    Vizon: [],
    XLR: [],
    XT5: [],
    XTS: []
  },
  Caterham: {
    "7": [],
    Beaulieu: [],
    "Superlight R": []
  },
  Chevrolet: {
    Alero: [],
    Astro: [],
    Avalanche: [],
    Aveo: [],
    Blazer: [],
    Camaro: [],
    "Camaro Cabriolet": [],
    "Camaro Coup\u00e9": [],
    Captiva: [],
    Cavalier: [],
    Celta: [],
    Cheyenne: [],
    "City Express": [],
    Cobalt: [],
    Colorado: [],
    Corvette: [],
    "Corvette Stingray": [],
    Cruze: [],
    "Cruze Station Wagon": [],
    Epica: [],
    Equinox: [],
    Evanda: [],
    Express: [],
    "Express Cargo": [],
    HHR: [],
    Impala: [],
    Journey: [],
    Kalos: [],
    Kodiak: [],
    Lacetti: [],
    Lumina: [],
    Malibu: [],
    "Malibu Hybrid": ["electroConfig1"],
    Matiz: ["municdirect"],
    "Monte Carlo": [],
    Nubira: [],
    Optra: [],
    Orlando: [],
    Prizm: [],
    Relsamo: [],
    Rezzo: [],
    "S-10": [],
    SS: [],
    SSR: [],
    Sabia: [],
    Silverado: [],
    "Silverado 1500": [],
    "Silverado 2500HD": [],
    "Silverado 3500HD": [],
    Sonic: [],
    Spark: [],
    "Spark EV": ["electroConfig1"],
    Suburban: [],
    Tahoe: [],
    Tracker: [],
    TrailBlazer: [],
    "Trans Sport": [],
    Traverse: [],
    Trax: [],
    Uplander: [],
    Venture: [],
    Vivant: [],
    Volt: ["electroConfig1"]
  },
  Chrysler: {
    "100": [],
    "200": [],
    "200 Convertible": [],
    "300": [],
    "300 C": [],
    "300 C Touring": [],
    "300M": [],
    Airflite: [],
    Aspen: [],
    "Aspen Hybrid": ["electroConfig1"],
    Cirrus: [],
    Concorde: [],
    Crossfire: [],
    Delta: [],
    "Grand Voyager": ["electroConfig1"],
    LHS: [],
    Neon: [],
    "PT Cruiser": [],
    "PT Dream Cruiser": [],
    Pacifica: [],
    "Pacifica Hybrid": ["electroConfig1"],
    Sebring: [],
    "Town & Country": [],
    "Town and Country": [],
    Viper: [],
    Voyager: [],
    Ypsilon: []
  },
  Citroen: {
    Berlingo: [],
    "Berlingo Electric": ["electroConfig1"],
    C: [],
    "C 15": [],
    "C-Airdream": [],
    "C-Crosser": [],
    "C-Zero": ["electroConfig1"],
    C1: [],
    C2: [],
    C3: [],
    "C3 Picasso": [],
    "C3 Pluriel": [],
    C30: [],
    C4: [],
    "C4 Aircross": [],
    "C4 Coup\u00e9": [],
    "C4 Picasso": [],
    C5: [],
    "C5 Kombi": [],
    "C5 Tourer": [],
    C6: [],
    C8: [],
    DS3: [],
    "DS3 Cabrio": [],
    DS4: [],
    DS5: [],
    "DS5 Hybrid": ["electroConfig1"],
    "DS7 Crossback": [],
    "Grand C4 Picasso": [],
    Jumper: [],
    Jumpy: [],
    Multispace: [],
    Nemo: [],
    Osee: [],
    Picasso: [],
    Saxo: [],
    "Saxo Electrique": ["electroConfig1"],
    SpaceTourer: [],
    Xantia: [],
    Xsara: [],
    "Xsara Picasso": []
  },
  DKSK: {
    "Glory 580": []
  },
  Dacia: {
    Dokker: [],
    Duster: [],
    Lodgy: [],
    Logan: [],
    "Logan MCV": [],
    Sandero: [],
    "Sandero Stepway": [],
    Supernova: []
  },
  Daewoo: {
    Chairman: [],
    Espero: [],
    Evanda: [],
    Kalos: [],
    Korando: [],
    Lacetti: [],
    Lanos: [],
    Leganza: [],
    Magnus: [],
    Matiz: [],
    Nubira: [],
    Rexton: [],
    Rezzo: [],
    Tacuma: [],
    Tocsa: [],
    Vada: []
  },
  Daihatsu: {
    Altis: [],
    Atrai: [],
    Charade: [],
    Copen: [],
    Cuore: [],
    Materia: [],
    Move: [],
    Muse: [],
    Naked: [],
    Sirion: [],
    Terios: [],
    "Terios II": [],
    Trevis: [],
    "U4 B": [],
    YRV: []
  },
  "De Tomaso": {
    Guara: [],
    Pantera: []
  },
  Dodge: {
    Avenger: [],
    Caliber: [],
    Caravan: [],
    Challenger: [],
    Charger: [],
    "Charger RT Concept": [],
    DART: [],
    Dakota: [],
    Durango: [],
    "Grand Caravan": [],
    "Hemi Super 8": [],
    Intrepid: [],
    Journey: [],
    Kahuna: [],
    M80: [],
    Magnum: [],
    Neon: [],
    Nitro: [],
    "Power Box": [],
    Ram: ["electroConfig1"],
    "Ram SRT": [],
    Razor: [],
    "SRT Viper": [],
    Sprinter: [],
    Stratus: [],
    Viper: [],
    "Viper SRT": []
  },
  Donkervoort: {
    D8: []
  },
  Ferrari: {
    "360": [],
    "456M": [],
    "458": [],
    "458 Italia": [],
    "458 Speciale": [],
    "488 GTB (458 Italia)": [],
    "488 Pista": [],
    "550": [],
    "575 Superamerica": [],
    "575M Maranello": [],
    "599": [],
    "599 GTB Fiorano": [],
    "612": [],
    "612 Scaglietti": [],
    "812": [],
    California: [],
    "California T": [],
    Enzo: [],
    "F 2005": [],
    "F12 Berlinetta": [],
    "F12 TRS": [],
    F430: [],
    F550: [],
    "F60 America": [],
    FF: [],
    "FF HELE": [],
    GTC4Lusso: [],
    LaFerrari: [],
    "LaFerrari Hybrid": ["electroConfig1"],
    Maranello: [],
    Portofino: [],
    Rossa: [],
    Sergio: [],
    Superamerica: []
  },
  Fiat: {
    "124 Spider": [],
    "500": [],
    "500 Abarth": [],
    "500C": [],
    "500C Abarth": [],
    "500L": [],
    "500X": [],
    "500e": [],
    "595 Abarth": [],
    "595C Abarth": [],
    Albea: [],
    Barchetta: [],
    Brava: [],
    Bravo: [],
    Croma: [],
    Doblo: [],
    Ducato: [],
    Duna: [],
    Ecobasic: [],
    Ecobasis: [],
    Fiorino: [],
    Freemont: ["electroConfig1"],
    Fullback: [],
    "Grande Punto": [],
    Idea: [],
    Linea: [],
    "Marea Weekend": [],
    Multipla: [],
    Palio: [],
    "Palio II": [],
    Panda: [],
    Punto: [],
    "Punto Abarth": [],
    Qubo: [],
    Scudo: [],
    "Scudo Kombi": [],
    Sedici: [],
    Seicento: [],
    "Seicento Elettra": ["electroConfig1"],
    Siena: [],
    Stilo: [],
    "Stilo Multi Wagon": [],
    Strada: [],
    Talento: [],
    Tipo: [],
    Ulysse: []
  },
  Fisker: {
    Karma: ["electroConfig1"]
  },
  Ford: {
    "427": [],
    "49": [],
    "B-MAX": [],
    Bantam: [],
    "C-MAX": [],
    "C-Max Energi": ["electroConfig1"],
    "C-Max Hybrid": ["electroConfig1"],
    Cougar: [],
    "Crown Victoria": [],
    "E-150": [],
    "E-250": [],
    "E-350": [],
    "E-Series": [],
    "E-Series Van": [],
    "E-Series Wagon": [],
    EX: [],
    Econoline: [],
    Econovan: [],
    Ecosport: [],
    Edge: [],
    Escape: [],
    Escort: [],
    Excursion: [],
    Expedition: [],
    Explorer: [],
    "Extreme EX": [],
    "F-150": [],
    "F-250": [],
    "F-250 Super Duty": [],
    "F-350": [],
    "F-350 Super Duty": [],
    "F-450": [],
    "F-450 Super Duty": [],
    "F-650": [],
    "FPV BF GT": [],
    Fairlane: [],
    Falcon: [],
    Fiesta: [],
    "Five Hundred": [],
    Flex: [],
    Focus: [],
    "Focus CC": [],
    "Focus Electric": ["electroConfig1"],
    "Focus ST": [],
    "Focus Turnier": [],
    Freestar: [],
    Freestyle: [],
    Fusion: [],
    "Fusion Energi": ["electroConfig1"],
    "Fusion Hybrid": ["electroConfig1"],
    GT: [],
    "GT 40": [],
    "GT 500": [],
    Galaxy: [],
    "Grand C-MAX": [],
    Ikon: [],
    Ka: [],
    Kuga: [],
    "Kuga Hybrid": ["electroConfig1"],
    Laser: [],
    Lynx: [],
    Maverick: [],
    "Model U": [],
    Mondeo: [],
    "Mondeo Hybrid": ["electroConfig1"],
    "Mondeo Turnier": [],
    Mustang: [],
    "Mustang GT Cabrio": [],
    Nugget: [],
    Puma: [],
    Ranger: [],
    "Ranger Pick-Up": [],
    "Ranger Wildtrak": [],
    "S-Max": [],
    "Shelby GR-1 Concept": [],
    "Shelby GT500": [],
    Streetka: [],
    "TE-50": [],
    Taurus: [],
    "Taurus X": [],
    Territory: [],
    Think: ["electroConfig1"],
    "Think Neighbor": ["electroConfig1"],
    Thunderbird: [],
    Tonka: [],
    Tourneo: [],
    Transit: [],
    "Transit Connect": [],
    "Transit Courier": [],
    "Transit Van": [],
    "Transit Wagon": [],
    "Urban Explorer": [],
    Windstar: [],
    "XR 8 Xplod": []
  },
  GMC: {
    Acadia: [],
    Autonomy: [],
    Canyon: [],
    Envoy: [],
    Jimmy: [],
    Safari: [],
    Savana: [],
    Sierra: [],
    "Sierra 1500": [],
    "Sierra 2500HD": [],
    "Sierra 3500HD": [],
    Sonoma: [],
    "Terra 4": [],
    Terracross: [],
    Terrain: [],
    Yukon: [],
    "Yukon XL": []
  },
  Gaz: {
    "3110": [],
    "3111": []
  },
  Geely: {
    CK: [],
    Ck1: []
  },
  Ginetta: {
    G20: [],
    G27: [],
    G34: [],
    G40: []
  },
  Holden: {
    Astra: [],
    Barina: [],
    Berlina: [],
    Calais: [],
    Caprice: [],
    Captiva: [],
    Colorado: [],
    Combo: [],
    Commodore: [],
    Cruze: [],
    Epica: [],
    Frontera: [],
    GTS: [],
    HSV: [],
    Jackaroo: [],
    Maloo: [],
    Monaro: [],
    Rodeo: [],
    Statesman: [],
    UTE: [],
    Vectra: [],
    Zafira: []
  },
  Honda: {
    Accord: [],
    "Accord Crosstour": [],
    "Accord Hybrid": ["electroConfig1"],
    "Accord Plug-In Hybrid": ["electroConfig1"],
    "Accord Tourer": [],
    Acty: [],
    Avancier: [],
    "CR-V": [],
    "CR-V Hybrid": ["electroConfig1"],
    "CR-Z": ["electroConfig1"],
    Capa: [],
    City: [],
    Civic: [],
    "Civic Hybrid": ["electroConfig1"],
    Crosstour: [],
    Element: [],
    "FR-V": [],
    Fit: [],
    "Fuya Jo": [],
    "HR-V": [],
    Insight: ["electroConfig1"],
    Inspire: [],
    Integra: [],
    Jazz: [],
    "Jazz Hybrid": ["electroConfig1"],
    Lagreat: [],
    Legend: [],
    Life: [],
    Logo: [],
    Mobilio: [],
    "Model X": [],
    NSX: [],
    Odyssey: [],
    Passport: [],
    Pilot: [],
    Prelude: [],
    Ridgeline: [],
    "S-MX": [],
    S2000: [],
    Saber: [],
    "Step Wagon": [],
    "Step Wagon Hybrid": ["electroConfig1"],
    Stream: [],
    That: [],
    Torneo: [],
    Vamos: [],
    Z: []
  },
  Hummer: {
    H1: [],
    H2: ["electroConfig1"],
    H3: []
  },
  Hyundai: {
    Accent: [],
    Atos: [],
    Azera: [],
    Clix: [],
    Coupé: [],
    Dynasty: [],
    Elantra: [],
    "Elantra GT": [],
    Entourage: [],
    Equus: [],
    Galloper: [],
    Genesis: [],
    "Genesis Coup\u00e9": [],
    Getz: [],
    "Grand Santa Fe": [],
    Grandeur: [],
    "H-1 Travel": [],
    H1: [],
    H100: [],
    H350: [],
    "HCD-7": [],
    "HED-5": [],
    "HED-6": [],
    Ioniq: ["electroConfig1"],
    Kona: [],
    "Kona Elektro": ["electroConfig1"],
    Matrix: [],
    Nexo: ["electroConfig1"],
    "Panel Van": [],
    Portico: [],
    "Santa Fe": [],
    "Santa Fe Sport": [],
    Satellite: [],
    Sonata: [],
    "Sonata Hybrid": ["electroConfig1"],
    Terracan: [],
    Tiburon: [],
    Tipper: [],
    Trajet: [],
    Tucson: [],
    Veloster: [],
    Veracruz: [],
    XG: [],
    "XG 300": [],
    "XG 350": [],
    i10: [],
    i20: [],
    i30: [],
    "i30 Coup\u00e9": [],
    i30cw: ["distanceCorrection7"],
    i40: [],
    i40cw: [],
    ix20: [],
    ix35: []
  },
  Infiniti: {
    EX: [],
    EX30d: [],
    EX37: [],
    FX: [],
    FX30d: [],
    FX37: [],
    FX50: [],
    G: [],
    G20: [],
    G25: [],
    G35: [],
    G37: [],
    "G37 Cabriolet": [],
    "G37 Coup\u00e9": [],
    I: [],
    I35: [],
    IPL: [],
    JX: [],
    "JX Hybrid": ["electroConfig1"],
    M: [],
    "M Hybrid": ["electroConfig1"],
    M30d: [],
    M35h: ["electroConfig1"],
    M37: [],
    Q30: [],
    Q40: [],
    Q45: [],
    Q50: [],
    "Q50 Hybrid": ["electroConfig1"],
    "Q60 Cabriolet": [],
    "Q60 Convertible": [],
    "Q60 Coup\u00e9": [],
    Q70: [],
    "Q70 Hybrid": ["electroConfig1"],
    QX: [],
    QX4: [],
    QX50: [],
    QX56: [],
    QX60: [],
    "QX60 Hybrid": ["electroConfig1"],
    QX70: [],
    QX80: []
  },
  Isuzu: {
    Ascender: [],
    Axiom: [],
    "D-MAX": [],
    "I-280": [],
    "I-290": [],
    "I-350": [],
    KB: [],
    Rodeo: [],
    Trooper: [],
    VehiCross: [],
    Wizard: [],
    ZXS: []
  },
  Italdesign: {
    Buron: [],
    "Twenty Twenty": [],
    Visconti: [],
    Volta: []
  },
  Iveco: {
    Daily: []
  },
  Jaguar: {
    "E-Pace": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "F-Pace": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "F-Type": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "I-Pace": ["electroConfig1", "Contentful:onboarding_warning:Jaguar"],
    "R Coup\u00e9": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "R-D6": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "S-Type": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "X-Type": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "X-Type Estate": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    X400: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XE: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XF: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar", "municdirect"],
    "XF Sportbrake": [
      "jaguar_obd",
      "Contentful:onboarding_warning:Jaguar",
      "municdirect"
    ],
    XFR: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "XFR-S": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XJ: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XJ220: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XJ6: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XJ8: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XJR: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XK: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XK8: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XKR: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "XKR Cabriolet": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "XKR Coup\u00e9": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "XKR-S Cabriolet": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    "XKR-S Coup\u00e9": ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XQ: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"],
    XS: ["jaguar_obd", "Contentful:onboarding_warning:Jaguar"]
  },
  Jeep: {
    Cherokee: [],
    Commander: [],
    Compass: ["electroConfig1"],
    "Grand Cherokee": [],
    Liberty: [],
    Patriot: [],
    Renegade: ["electroConfig1"],
    Willys: [],
    "Willys 2": [],
    Wrangler: []
  },
  Jensen: {
    "SV-8": []
  },
  Kia: {
    Amanti: [],
    Borrego: [],
    Cadenza: [],
    Carens: [],
    Carnival: [],
    "Cee'd": [],
    Cerato: [],
    "E Soul": ["electroConfig1"],
    Forte: [],
    K2700: [],
    K900: [],
    "KCV-4": [],
    Magentis: [],
    "Magentis Hybrid": ["electroConfig1"],
    Niro: ["electroConfig1"],
    Opirus: [],
    Optima: [],
    "Optima Hybrid": ["electroConfig1"],
    Picanto: ["electroConfig1"],
    Potentia: [],
    Pregio: [],
    "Pride Wagon": [],
    "Pro-Cee'd": [],
    Retona: [],
    Rio: [],
    Rondo: [],
    Sedona: [],
    "Sephia II": [],
    Shuma: [],
    "Shuma II": [],
    Sorento: [],
    Soul: [],
    "Soul EV": ["electroConfig1"],
    Spectra: [],
    Spectra5: [],
    Sportage: [],
    Stinger: [],
    Stonic: [],
    Towner: ["electroConfig1"],
    Venga: [],
    Visto: [],
    XCeed: []
  },
  Koenigsegg: {
    Agera: [],
    CC8S: [],
    CCR: [],
    CCX: [],
    CCXR: []
  },
  Lada: {
    "110": [],
    "111": [],
    "112": [],
    "117": [],
    "119": [],
    "21": [],
    "2110": [],
    "2111": [],
    "2123": [],
    "Calina 1118": [],
    Granta: [],
    Kalina: [],
    Niva: [],
    "Niva II": [],
    Oka: [],
    Priora: [],
    Roadster: [],
    Samara: [],
    "Samara II": []
  },
  Lamborghini: {
    Aventador: [],
    "Concept S": [],
    Diablo: [],
    Gallardo: [],
    Huracan: [],
    Murcielago: [],
    "Sesto Elemento": [],
    Urus: [],
    Veneno: []
  },
  Lancia: {
    Delta: [],
    Dialogos: [],
    Flavia: [],
    Fulvia: [],
    Lybra: [],
    Musa: [],
    Nea: [],
    Phedra: [],
    Thema: [],
    Thesis: [],
    Voyager: ["electroConfig1"],
    Ypsilon: [],
    Zeta: []
  },
  "Land Rover": {
    Defender: ["Contentful:onboarding_warning:Land Rover"],
    Discovery: ["Contentful:onboarding_warning:Land Rover"],
    "Discovery 3": ["Contentful:onboarding_warning:Land Rover"],
    "Discovery 4": ["Contentful:onboarding_warning:Land Rover"],
    "Discovery 5": ["Contentful:onboarding_warning:Land Rover"],
    Freelander: ["Contentful:onboarding_warning:Land Rover", "municdirect"],
    LR2: ["Contentful:onboarding_warning:Land Rover"],
    LR3: ["Contentful:onboarding_warning:Land Rover"],
    LR4: ["Contentful:onboarding_warning:Land Rover"],
    "Range Rover": ["Contentful:onboarding_warning:Land Rover", "municdirect"],
    "Range Rover Evoque": ["Contentful:onboarding_warning:Land Rover"],
    "Range Rover Evoque Coup\u00e9": [
      "Contentful:onboarding_warning:Land Rover"
    ],
    "Range Rover Hybrid": [
      "Contentful:onboarding_warning:Land Rover",
      "electroConfig1"
    ],
    "Range Rover Sport": [
      "Contentful:onboarding_warning:Land Rover",
      "municdirect"
    ],
    "Range Rover Sport Hybrid": [
      "Contentful:onboarding_warning:Land Rover",
      "electroConfig1"
    ],
    "Range Rover Velar": [
      "Contentful:onboarding_warning:Land Rover",
      "municdirect"
    ]
  },
  Lexus: {
    CT: [],
    "CT 200h": ["electroConfig1"],
    ES: [],
    "ES 300h": ["electroConfig1"],
    "ES 350": [],
    GS: [],
    "GS 300h": ["electroConfig1"],
    "GS 350": [],
    "GS 450h": ["electroConfig1"],
    "GS F": [],
    GX: [],
    "GX 460": [],
    HS: ["electroConfig1"],
    IS: [],
    "IS 250": [],
    "IS 250 C": [],
    "IS 300h": ["electroConfig1"],
    "IS 350": [],
    "IS 350 C": [],
    "IS F": [],
    "LC 500": [],
    "LC 500h": ["electroConfig1"],
    "LF-C": [],
    LFA: [],
    LS: [],
    "LS 460": [],
    "LS 600h L": ["electroConfig1"],
    LX: [],
    "LX 570": [],
    "Minority Report": [],
    NX: [],
    "NX 200t": [],
    "NX 300h": ["electroConfig1"],
    "RC 350": [],
    "RC 350h": ["electroConfig1"],
    "RC F": [],
    RX: [],
    "RX 350": [],
    "RX 450h": ["electroConfig1"],
    SC: [],
    "UX 250h": ["electroConfig1"]
  },
  Lincoln: {
    Aviator: [],
    Blackwood: [],
    Continental: [],
    LS: [],
    "MK 9": [],
    MKC: [],
    MKS: [],
    MKT: [],
    MKX: [],
    MKZ: [],
    "MKZ Hybrid": ["electroConfig1"],
    "Mark LT": [],
    "Mark X": [],
    Navigator: [],
    "Town Car": [],
    Zephyr: [],
    "Zephyr Hybrid": ["electroConfig1"]
  },
  Lotus: {
    Elan: [],
    Elise: [],
    Elite: [],
    Esprit: [],
    Eterne: [],
    Evora: [],
    Exige: []
  },
  Luxgen: {
    "Luxgen M7": [],
    "Luxgen M7 Hybrid": ["electroConfig1"],
    Luxgen7: [],
    "Luxgen7 Hybrid": ["electroConfig1"]
  },
  MAN: {
    Sonstiges: [],
    TGE: []
  },
  MG: {
    Rover: [],
    TF: [],
    X10: [],
    X20: [],
    X80: [],
    XPower: [],
    ZR: [],
    ZS: [],
    ZT: [],
    "ZT-T": []
  },
  Mahindra: {
    Armada: [],
    Bolero: [],
    CL: [],
    Commander: [],
    Scorpio: []
  },
  Marcos: {
    GTS: [],
    TS: [],
    TS500: [],
    TSO: []
  },
  Maserati: {
    "3200 GT": [],
    Coupé: [],
    GT: [],
    "GT MC Stradale": [],
    "GT Sport": [],
    Ghibli: [],
    GranCabrio: [],
    GranSport: [],
    GranTurismo: [],
    "GranTurismo Convertible": [],
    Kubang: [],
    Levante: [],
    MC12: [],
    Quattroporte: [],
    Spyder: [],
    "V8 GranSport": []
  },
  Mazda: {
    "2": [],
    "3": [],
    "323": [],
    "5": [],
    "6": [],
    "6 Kombi": [],
    "626": [],
    AZ: [],
    Atenza: [],
    B2300: [],
    B2500: [],
    B3000: [],
    B4000: [],
    "BT-50": [],
    "Brown Collection Verisa": [],
    "CU-X": [],
    "CX-05": [],
    "CX-09": [],
    "CX-3": [],
    "CX-30": [],
    "CX-5": [],
    "CX-7": [],
    "CX-9": [],
    Carol: [],
    Demio: [],
    Drifter: [],
    DrifterX: [],
    Etude: [],
    Kusabi: [],
    Laputa: [],
    MAZDA2: [],
    MAZDA3: [],
    MAZDA6: [],
    MPV: [],
    MX: [],
    "MX-5": [],
    "MX-5 Miata": [],
    "MX-Flexa": [],
    Marathon: [],
    Mazda5: [],
    MazdaSpeed3: [],
    MazdaSpeed6: [],
    Premacy: [],
    "RX-8": [],
    Roadster: [],
    Rustler: [],
    SPEED3: [],
    Spiano: [],
    "Sport Wagon": [],
    Tribute: [],
    "Tribute HEV": ["electroConfig1"],
    Washu: [],
    "Xedos 9": []
  },
  McLaren: {
    "570GT": [],
    "570S": [],
    "570S Spider": [],
    "620R": [],
    "650S Coup\u00e9": [],
    "650S Spider": [],
    "675LT": [],
    "675LT Spider": [],
    "720S": [],
    F1: [],
    MP4: [],
    "MP4-12C": [],
    P1: ["electroConfig1"]
  },
  "Mercedes-Benz": {
    A: [],
    "AMD GT": [],
    "AMG GT": [],
    "AMG GTS": [],
    Atego: ["electroConfig1"],
    B: [],
    "B Elektro": ["electroConfig1"],
    C: [],
    "C Coup\u00e9": [],
    "C Coup\u00e9 Hybrid": ["electroConfig1"],
    "C Hybrid": ["electroConfig1"],
    "C Sportscoup\u00e9": [],
    "C Sportscoup\u00e9 Hybrid": ["electroConfig1"],
    "C T-Modell": [],
    "C T-Modell Hybrid": ["electroConfig1"],
    CL: [],
    CLA: [],
    CLC: [],
    CLK: [],
    CLS: [],
    Citan: [],
    "Citan extralang": [],
    "Citan lang": [],
    E: [],
    "E Cabriolet": [],
    "E Cabriolet Hybrid": ["electroConfig1"],
    "E Coup\u00e9": [],
    "E Coup\u00e9 Hybrid": ["electroConfig1"],
    "E Hybrid": ["electroConfig1"],
    "E T-Modell": [],
    "E T-Modell Hybrid": ["electroConfig1"],
    EQC: ["electroConfig1"],
    G: ["municdirect"],
    GL: [],
    GLA: [],
    GLB: [],
    GLC: [],
    "GLC FC": ["electroConfig1"],
    "GLC Hybrid": ["electroConfig1"],
    GLE: [],
    "GLE Hybrid": ["electroConfig1"],
    GLK: [],
    GLS: [],
    M: [],
    "M Hybrid": ["electroConfig1"],
    ML: [],
    "ML Hybrid": ["electroConfig1"],
    "Marco Polo": [],
    Metris: [],
    R: [],
    S: ["municdirect"],
    "S Coup\u00e9": ["municdirect"],
    "S Hybrid": ["electroConfig1"],
    SL: [],
    SLC: [],
    SLK: [],
    "SLR McLaren": [],
    SLS: [],
    "SLS AMG GT": [],
    "SLS AMG GT electric": ["electroConfig1"],
    "SLS Coup\u00e9": [],
    "SLS Hybrid": ["electroConfig1"],
    "SLS Roadster": [],
    Sprinter: [],
    V: [],
    Vaneo: [],
    Viano: [],
    Vito: [],
    "Vito Kastenwagen": [],
    X: ["electroConfig1"],
    eVito: ["electroConfig1"]
  },
  "Mercedes-Maybach": {
    "S-Klasse": [],
    "S-Klasse Hybrid": ["electroConfig1"]
  },
  Mercury: {
    Cougar: [],
    "Grand Marquis": [],
    Marauder: [],
    Mariner: [],
    "Mariner Hybrid": ["electroConfig1"],
    Milan: [],
    Montego: [],
    Monterey: [],
    Mountaineer: [],
    Mystique: [],
    Sable: [],
    Villager: []
  },
  Mini: {
    Cabrio: [],
    Clubman: [],
    Cooper: [],
    "Cooper Clubman": [],
    "Cooper Countryman": [],
    "Cooper Countryman Hybrid": ["electroConfig1"],
    "Cooper Coup\u00e9": [],
    "Cooper Paceman": [],
    "Cooper Roadster": [],
    "Cooper S": [],
    Coupé: [],
    "John Cooper Works": [],
    One: [],
    "One Cabrio": [],
    Paceman: [],
    Roadster: []
  },
  Mitsubishi: {
    ASX: [],
    Carisma: [],
    Colt: [],
    Diamante: [],
    Dingo: [],
    Dion: [],
    Eclipse: [],
    Endeavor: [],
    Galant: [],
    Grandis: [],
    "L 200": [],
    Lancer: [],
    "Lancer Evolution": [],
    "Lancer Sportback": [],
    Magna: [],
    Minica: [],
    Mirage: [],
    Montero: [],
    Nessie: [],
    Outlander: [],
    "Outlander Hybrid": ["electroConfig1"],
    "Outlander Sport": [],
    Pajero: [],
    "Pajero Pinin": [],
    Proudia: [],
    "RPM 7000": [],
    RVR: [],
    Raider: [],
    SUP: [],
    SUW: [],
    Shogun: [],
    "Shogun Pinin": [],
    "Space Liner": [],
    "Space Star": [],
    "Space Wagon": [],
    Verada: [],
    eK: [],
    "i-MiEV": ["electroConfig1"]
  },
  Morgan: {
    "4": [],
    "4 Seater": [],
    "44": [],
    Aero: [],
    "Aero 8": [],
    Aeromax: [],
    "Plus 8": [],
    Roadster: []
  },
  Nissan: {
    "1400": [],
    "350Z": [],
    "370Z": [],
    AXY: [],
    Almera: [],
    "Almera Tino": [],
    Alpha: [],
    Altima: [],
    Armada: [],
    Avenir: [],
    Bluebird: [],
    Chappo: [],
    Cima: [],
    "Cima Hybrid": ["electroConfig1"],
    Commercial: [],
    Cube: [],
    Evalia: [],
    Frontier: [],
    Fusion: [],
    "GT-R": [],
    "Grand Livina": [],
    Hardbody: [],
    HyperMini: ["electroConfig1"],
    Ideo: [],
    Interstar: [],
    Juke: ["distanceCorrection6"],
    Lafesta: [],
    Laurel: [],
    Leaf: ["electroConfig1"],
    Livina: [],
    M: [],
    MM: [],
    Maxima: [],
    "Maxima QX": [],
    Micra: ["electroConfig1"],
    Moco: [],
    "Moco E": ["electroConfig1"],
    Murano: [],
    "Murano CrossCabriolet": [],
    "Murano Hybrid": ["electroConfig1"],
    NP300: [],
    NV: [],
    NV200: [],
    NV400: [],
    Navara: ["electroConfig1"],
    Note: ["distanceCorrection7"],
    Pathfinder: [],
    "Pathfinder Hybrid": ["electroConfig1"],
    Patrol: [],
    Pickup: [],
    Pixo: [],
    Platina: [],
    Primastar: [],
    Primera: [],
    Pulsar: [],
    Qashqai: [],
    "Qashqai+2": [],
    Quest: [],
    "R 390 GT1": [],
    Rogue: [],
    Sentra: [],
    Serena: [],
    Silvia: [],
    Skyline: [],
    Sport: [],
    Terrano: [],
    "Terrano II": [],
    Tiida: [],
    Titan: [],
    Versa: [],
    "Versa Note": [],
    "X-Trail": [],
    Xterra: [],
    Z: [],
    Zaroot: [],
    "e-NV200": ["electroConfig1"]
  },
  Noble: {
    M12: [],
    M14: []
  },
  Oldsmobile: {
    Alero: [],
    Aurora: [],
    Bravada: [],
    Intrigue: [],
    O4: [],
    Silhouette: []
  },
  Opel: {
    ADAM: [],
    Agila: [],
    Ampera: ["electroConfig1"],
    Antara: [],
    Astra: [],
    "Astra CC": [],
    "Astra Cabrio": [],
    "Astra Caravan": [],
    "Astra Coup\u00e9": [],
    "Astra GTC": [],
    "Astra OPC": [],
    "Astra Sports Tourer": [],
    Cascada: [],
    Combo: [],
    "Concept M": [],
    Corsa: [],
    "Crossland X": [],
    Filo: [],
    Frogster: [],
    Frontera: [],
    GT: [],
    "Grandland X": [],
    Insignia: [],
    "Insignia Sports Tourer": [],
    Karl: [],
    M: [],
    Meriva: [],
    Mokka: [],
    Movano: [],
    Omega: [],
    Signum: [],
    Speedster: [],
    Tigra: [],
    Trixx: [],
    Vectra: [],
    "Vectra Caravan": [],
    Vivaro: [],
    Zafira: [],
    "Zafira Tourer": []
  },
  Other: {
    Other: []
  },
  Pagani: {
    Huayra: [],
    Zonda: []
  },
  Panoz: {
    AIV: [],
    Esperante: []
  },
  Peugeot: {
    "1007": [],
    "106": [],
    "107": [],
    "108": [],
    "2008": [],
    "206": [],
    "207": [],
    "207 CC": [],
    "207 SW": [],
    "208": [],
    "3008": [],
    "306": [],
    "307": [],
    "307 CC": [],
    "307 SW": [],
    "308": [],
    "308 CC": [],
    "308 SW": [],
    "4007": [],
    "4008": [],
    "405": [],
    "406": [],
    "407": [],
    "407 Coup\u00e9": [],
    "407 SW": [],
    "408": [],
    "5008": [],
    "508": [],
    "508 SW": [],
    "605": [],
    "607": [],
    "806": [],
    "807": [],
    "907": [],
    Bipper: [],
    Boxer: [],
    Expert: [],
    H2O: [],
    Partner: [],
    Promethee: [],
    RC: [],
    RCZ: [],
    Rifter: [],
    Sesame: [],
    Traveller: [],
    Vroomster: [],
    iOn: []
  },
  Pininfarina: {
    Birdcage: [],
    Enjoy: [],
    Start: []
  },
  Pontiac: {
    Aztek: [],
    Bonneville: [],
    Firebird: [],
    G3: [],
    G5: [],
    G6: [],
    G8: [],
    GTO: [],
    "Grand Am": [],
    "Grand Prix": [],
    Montana: [],
    "SV 6": [],
    Solstice: [],
    Sunfire: [],
    Torrent: [],
    "Trans Am": [],
    Vibe: []
  },
  Porsche: {
    "911": ["municdirect"],
    "918": [],
    "918 Spyder": ["electroConfig1"],
    "996": [],
    Boxster: [],
    Boxter: [],
    "Carrera GT": [],
    Cayenne: ["distanceCorrection7"],
    "Cayenne Hybrid": ["electroConfig1"],
    Cayman: [],
    GT2: [],
    Macan: [],
    Panamera: [],
    "Panamera Hybrid": ["electroConfig1"],
    "Taycan Turbo": ["electroConfig1"]
  },
  Proton: {
    "300": [],
    "400": [],
    Arena: [],
    Gen: [],
    "Gen-2": [],
    Impian: [],
    Juara: [],
    Perdana: [],
    Saloon: [],
    Satria: [],
    Savvy: [],
    Tiara: []
  },
  Renault: {
    Alaskan: [],
    Avantime: [],
    "Be Bop": [],
    Captur: [],
    Clio: [],
    "Clio Grandtour": [],
    Duster: [],
    Ellypse: [],
    Espace: [],
    Fluence: ["electroConfig1"],
    "Grand Espace": [],
    "Grand Kangoo": [],
    "Grand Modus": [],
    "Grand Scenic": [],
    Kadjar: [],
    Kangoo: [],
    "Kangoo Z.E.": ["electroConfig1"],
    Koleos: [],
    Laguna: [],
    "Laguna Coup\u00e9": [],
    "Laguna Grandtour": [],
    Latitude: [],
    Logan: [],
    Master: [],
    Megane: [],
    "Megane Coup\u00e9": [],
    "Megane Coup\u00e9-Cabrio": [],
    "Megane Grandtour": [],
    Modus: [],
    Sandero: [],
    Scenic: [],
    "Scenic II": [],
    Symbol: [],
    Talisman: [],
    Thalia: [],
    Trafic: [],
    Twingo: [],
    Twizy: ["electroConfig1"],
    "Vel Satis": [],
    Wind: [],
    Zoe: ["electroConfig1"],
    Zoom: []
  },
  Riley: {
    "MR II": []
  },
  "Rolls-Royce": {
    "100": [],
    Corniche: [],
    "Drophead Coup\u00e9": [],
    Ghost: [],
    "Ghost Series II": [],
    "Park Ward": [],
    Phantom: [],
    "Phantom Coup\u00e9": [],
    "Phantom Drophead Coup\u00e9": [],
    "Silver Seraph": [],
    Wraith: []
  },
  Rover: {
    "25": [],
    "45": [],
    "75": [],
    City: [],
    Streetwise: []
  },
  SSC: {
    Aero: [],
    Tuatara: [],
    "Ultimate Aero": []
  },
  Saab: {
    "42438": [],
    "42499": [],
    "9-2x": [],
    "9-3 Cabrio": [],
    "9-3 SportCombi": [],
    "9-4X": [],
    "9-5": [],
    "9-5 SportCombi": [],
    "9-7X": [],
    "9-X": [],
    "9000": [],
    "93": []
  },
  Saleen: {
    S7: []
  },
  Samsung: {
    SM3: [],
    SM5: []
  },
  Saturn: {
    Astra: [],
    Aura: [],
    Curve: [],
    ION: [],
    L: [],
    LW: [],
    Outlook: [],
    Relay: [],
    SC: [],
    Sky: [],
    Vue: [],
    "Vue Hybrid": ["electroConfig1"]
  },
  Scion: {
    "FR-S": [],
    "FR-S Convertible": [],
    T2B: [],
    iM: [],
    iQ: [],
    tC: [],
    xA: [],
    xB: [],
    xD: []
  },
  Seat: {
    Alhambra: [],
    Altea: [],
    "Altea Freetrack": [],
    "Altea XL": [],
    Arona: [],
    Arosa: [],
    Ateca: [],
    Cordoba: [],
    Exeo: [],
    Ibiza: [],
    "Ibiza SC": [],
    "Ibiza ST": ["municdirect"],
    "Inca Kombi": [],
    Leon: [],
    Mii: [],
    Tango: [],
    Tarraco: [],
    Toledo: []
  },
  Skoda: {
    Citigo: [],
    Fabia: [],
    "Fabia Combi": [],
    "Fabia Scout": [],
    Kamiq: [],
    Karoq: [],
    Kodiaq: [],
    Montreux: [],
    Octavia: [],
    "Octavia Combi": ["municdirect"],
    Praktik: [],
    Rapid: [],
    Roomster: [],
    "Roomster Scout": [],
    Scala: [],
    Superb: [],
    "Superb Combi": [],
    Yeti: []
  },
  Smart: {
    Brabus: [],
    "Brabus Elektro": ["electroConfig1"],
    Coupé: [],
    "Coup\u00e9 Hybrid": ["electroConfig1"],
    ForFour: [],
    "ForFour Elektro": ["electroConfig1"],
    ForTwo: [],
    "ForTwo Elektro": ["electroConfig1"],
    Roadster: [],
    "fortwo cabrio": [],
    "fortwo coup\u00e9": []
  },
  Sonstige: {
    Anhänger: [],
    Sonstige: []
  },
  Spyker: {
    C8: []
  },
  SsangYong: {
    Actyon: [],
    "Chairman H": [],
    "Chairman W": [],
    Korando: [],
    Kyron: [],
    Musso: [],
    Rexton: [],
    Rodius: [],
    Stavic: []
  },
  Steyr: {
    "G-series": []
  },
  Subaru: {
    B: [],
    "B9 Tribeca": [],
    BRZ: [],
    Baja: [],
    Forester: [],
    G3X: [],
    "HM-01": [],
    Impreza: [],
    "Impreza WRX": [],
    Justy: [],
    Legacy: [],
    "Legacy Kombi": [],
    Levorg: [],
    Outback: [],
    "Outback Sport": [],
    Pleo: [],
    R2: [],
    Stella: [],
    Traviq: [],
    Trezia: [],
    Tribeca: [],
    WRX: [],
    "WX 01": [],
    XV: [],
    "XV Crosstrek": []
  },
  Suzuki: {
    Aerio: [],
    Alto: [],
    Baleno: [],
    Celerio: [],
    Covie: ["electroConfig1"],
    "EV Sport": [],
    Equator: [],
    F1: [],
    Forenza: [],
    "GSX R4": [],
    "Grand Vitara": [],
    Ignis: [],
    Jimny: [],
    Kizashi: [],
    Lapin: [],
    Liana: [],
    Reno: [],
    SX: [],
    SX4: [],
    "SX4 S-Cross": [],
    Samurai: [],
    "Sea Forenza Wagon": [],
    Splash: [],
    Swift: [],
    Twin: [],
    "Twin Hybrid": ["electroConfig1"],
    Verona: [],
    Vitara: [],
    "Wagon R+": [],
    X2: [],
    XL7: []
  },
  TVR: {
    Cerbera: [],
    Chimaera: [],
    Griffith: [],
    Sagaris: [],
    "Speed 12": [],
    "T 350": [],
    "T 440 R": [],
    Tamora: [],
    Tuscan: []
  },
  Tata: {
    Aria: [],
    "B-Line": [],
    E: [],
    Indica: [],
    Indigo: [],
    Mint: [],
    Nano: [],
    Safari: ["electroConfig1"],
    Sumo: []
  },
  Tesla: {
    "Model 3": [
      "electroConfig1",
      "Contentful:onboarding_warning:cable required"
    ],
    "Model S": [
      "electroConfig1",
      "Contentful:onboarding_warning:cable required"
    ],
    "Model X": [
      "electroConfig1",
      "Contentful:onboarding_warning:cable required"
    ]
  },
  Toyota: {
    "105": [],
    "4Runner": [],
    Allion: [],
    Altezza: [],
    Ateva: [],
    Auris: ["electroConfig1"],
    "Auris Hybrid": ["electroConfig1"],
    Avalon: [],
    "Avalon Hybrid": ["electroConfig1"],
    Avanza: [],
    Avensis: [],
    "Avensis Kombi": [],
    "Avensis Verso": [],
    Aygo: [],
    BB: [],
    Bandeirante: [],
    Brevis: [],
    "C-HR": ["electroConfig1"],
    "C-HR Hybrid": ["electroConfig1"],
    Caldina: [],
    Camry: [],
    "Camry Hybrid": ["electroConfig1"],
    Celica: [],
    Celsior: [],
    Condor: [],
    Corolla: [],
    "Corolla Hybrid": ["electroConfig1"],
    "Corolla Kombi": [],
    "Corolla Verso": [],
    Crown: [],
    Dyna: [],
    "ES 3": [],
    Echo: [],
    "F-1": [],
    "FCHV 5": [],
    FCV: [],
    "FJ Cruiser": [],
    FXS: [],
    Fortuner: [],
    GT86: [],
    HMV: ["electroConfig1"],
    "Hi-Ace": [],
    Hiace: [],
    Highlander: [],
    "Highlander Hybrid": ["electroConfig1"],
    Hilux: [],
    Ipsum: [],
    "Land Cruiser": [],
    "Land Cruiser Prado": [],
    "Land Cruiser V8": [],
    MR2: [],
    "Mark II": [],
    Matrix: [],
    "Mega Cruiser": [],
    Mirai: ["electroConfig1"],
    Opa: [],
    Pod: [],
    Previa: [],
    Prius: ["electroConfig1"],
    "Prius C": ["electroConfig1"],
    "Prius Plug-in": ["electroConfig1"],
    "Prius V": ["electroConfig1"],
    "Prius+": ["electroConfig1"],
    Proace: [],
    "Proace Verso": [],
    "Progress NC 300": [],
    Quantum: [],
    RAV4: [],
    "RAV4 Hybrid": ["electroConfig1"],
    RSC: [],
    Ractis: [],
    RunX: [],
    Sequoia: [],
    Sienna: [],
    Soarer: [],
    Solara: [],
    Sparky: [],
    Sprinter: [],
    Stallion: [],
    Supra: [],
    Tacoma: [],
    Tazz: [],
    Tundra: [],
    "Urban Cruiser": [],
    Venza: [],
    Verossa: [],
    Verso: ["municdirect"],
    "Verso-S": ["municdirect"],
    Vitz: [],
    Will: [],
    Windom: [],
    Yaris: ["electroConfig1"],
    "Yaris Verso": [],
    iQ: []
  },
  Vauxhall: {
    Agila: [],
    Astra: [],
    Combo: [],
    Corsa: [],
    Frontera: [],
    Meriva: [],
    Monaro: [],
    Omega: [],
    Signum: [],
    Tigra: [],
    "VX Lightning": [],
    VX220: [],
    Vectra: [],
    Vivaro: [],
    Zafira: []
  },
  Vector: {
    M12: []
  },
  Venturi: {
    Atlantique: []
  },
  Volkswagen: {
    Alltrack: [],
    Amarok: [],
    Arteon: [],
    Beetle: [],
    Bora: [],
    "Bora Variant": [],
    CC: [],
    Caddy: [],
    "Caddy Kastenwagen": [],
    "Caddy Kombi": [],
    Caravelle: [],
    Crafter: [],
    CrossGolf: [],
    CrossPolo: [],
    CrossTouran: [],
    Eos: [],
    Fox: [],
    Golf: ["Contentful:onboarding_warning:Golf"],
    "Golf Cabriolet": [],
    "Golf Hybrid": ["electroConfig1"],
    "Golf Performance": [],
    "Golf Plus": [],
    "Golf Sportsvan": ["Contentful:onboarding_warning:Golf Sportsvan"],
    "Golf Variant": ["Contentful:onboarding_warning:Golf Variant"],
    "ID.3": ["electroConfig1"],
    Jetta: ["municdirect"],
    "Jetta Hybrid": ["electroConfig1"],
    Lupo: [],
    Multivan: [],
    Passat: [],
    "Passat Hybrid": ["electroConfig1"],
    "Passat Variant": [],
    Phaeton: ["municdirect"],
    Pickup: [],
    Polo: [],
    Scirocco: [],
    Sharan: [],
    "T-Cross": [],
    "T-Cross Hybrid": ["electroConfig1"],
    "T-Roc": [],
    T5: [],
    T6: [],
    Tiguan: [],
    "Tiguan Allspace": [],
    Touareg: [],
    "Touareg Hybrid": ["electroConfig1"],
    Touran: [],
    Transporter: [],
    "e-Golf": ["electroConfig1"],
    "e-up!": ["electroConfig1"],
    "up!": []
  },
  Volvo: {
    C30: [],
    C70: [],
    S40: [],
    S60: [],
    S80: [],
    S90: [],
    "S90 Hybrid": ["electroConfig1"],
    V40: [],
    V50: [],
    V60: [],
    "V60 Cross Country": [],
    "V60 Hybrid": ["electroConfig1"],
    V70: [],
    V90: [],
    "V90 CC": [],
    "V90 Hybrid": ["electroConfig1"],
    X670: [],
    XC40: [],
    XC60: [],
    "XC60 Hybrid": ["electroConfig1"],
    XC70: [],
    XC90: [],
    "XC90 Hybrid": ["electroConfig1"]
  },
  Westfield: {
    "XTR 2": [],
    ZEI: []
  },
  ZAZ: {
    Slavuta: [],
    Tavrija: [],
    Wagon: []
  },
  Zenvo: {
    ST1: []
  },
  eGO: {
    "Life 20": ["electroConfig1"],
    "Life 40": ["electroConfig1"],
    "Life 60": ["electroConfig1"]
  },
  Portable: {
    Portable: []
  }
};
const expenseType = [
  { name: "INSURANCE" },
  { name: "MAINTENANCE" },
  { name: "LABOR" },
  { name: "PARKING" },
  { name: "FUEL" },
  { name: "LEASING" },
  { name: "TAXES" },
  { name: "TOLL" },
  { name: "OTHER" }
];

export default { vehicleModels, expenseType };
